

$brand-primary: #38a3bb;
$secondary-1: #f7931d;
$secondary-2: #fcb32e;
$black: #4b4b4b;
$gray: #a1a1a1;
$light-gray: #d8d8d8;
$white: #ffffff;
$bold: 'Source Sans Pro Bold', sans-serif;

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../static/fonts/SourceSansPro-Regular.ttf');
}

@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url('../static/fonts/SourceSansPro-Bold.ttf');
}


body {
    font-family: 'Source Sans Pro', sans-serif;
}

div#app {
    background-color: $brand-primary;
}
@media only screen
and (max-width: 400px) {
    .rc-slider-handle {
        top: -1.0rem !important;
    }
}


/* Portrait and Landscape */
@media only screen 
and (min-width: 320px)
and (max-width: 568px) {
    html {
        font-size: 0.9vw;
    }

    .app-container{
        background-color: $brand-primary; 
        height:100%; 
        width:100%;
        position: absolute;

    img.hourglass {
        height: 100%;
        width: 40%;
        margin-left: 30%;
        margin-right: 30%;
    }
    
    canvas.avatar-canvas{
        margin-left:10%;
        margin-right:10%;
        margin-bottom:9em;
        border: 2px solid $gray;
        width:80%;
        height:50rem;
        background-color:$white;
        border-radius:0.5em;
        z-index:10;
        display:block;
        touch-action:none;
    }

    button.begin-button{
        margin: 0;
        border-top-left-radius:  0;
        border-top-right-radius: 0;
        border: 0;
        border-top: 4px solid #d8d8d8;
        font-family: $bold;
        background-color: $white;
        color:$secondary-1;
        width:90%;
        font-size: 4rem;
        height: 7rem;
        font-weight:900;
        cursor: pointer;
    }
}
}

/* Landscape phone to portrait tablet */
@media only screen 
and (min-width: 569px)
and (max-width: 767px)
{
      html {
        font-size:0.8vw;
    }

    .app-container {
        background-color: $brand-primary;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    img.hourglass {
        height: 100%;
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
    }
    
    canvas.avatar-canvas{
        margin-left:10%;
        margin-right:10%;
        margin-bottom:9em;
        border: 2px solid $gray;
        width:80%;
        height:50rem;
        background-color:$white;
        border-radius:0.5em;
        z-index:10;
        display:block;
        touch-action:none;
    }

    button.begin-button{
        margin: 0;
        border-top-left-radius:  0;
        border-top-right-radius: 0;
        border: 0;
        border-top: 4px solid #d8d8d8;
        font-family: $bold;
        background-color: $white;
        color:$secondary-1;
        width:90%;
        font-size: 7rem;
        height: 2em;
        font-weight:900;
        cursor: pointer;
    }
}


@media only screen 
and (min-width: 768px)
and (max-width: 1024px)  {
    
        html {
            font-size:0.8vw;
        }

        .app-container{
            background-color: $brand-primary; 
            height:100%; 
            width:100%;
            position: absolute;
    
        img.hourglass {
            height: 80%;
            width: 40%;
            margin-left: 30%;
            margin-right: 30%;
        }
        
        canvas.avatar-canvas{
            margin-left:10%;
            margin-right:10%;
            margin-bottom:9em;
            border: 2px solid $gray;
            width:80%;
            height:50rem;
            background-color:$white;
            border-radius:0.5em;
            z-index:10;
            display:block;
            touch-action:none;
        }
    }

    input.game-select {
        font-family: $bold;
        height: 10rem;
        width: 45%;
        border: 2px solid $gray;
        border-radius:0.2em;
        text-align:center;
        font-size:8rem !important;
        color:#707070;
    }
}


@media only screen 
and (min-width: 1000px) {

    html {
        font-size:0.4vw;
    }
    .app-container{
            background-color: $brand-primary; 
            width:100%;
            height:100%;
            padding-left:25%;
            padding-right:25%;
            position: absolute;
    
    }

    img.hourglass {
        height: 10%;
        width: 40%;
        margin-left: 30%;
        margin-right: 30%;
    }

    canvas.avatar-canvas{
        margin-left:10%;
        margin-right:10%;
        margin-bottom:9em;
        border: 2px solid $gray;
        width:80%;
        height:70rem;
        background-color:$white;
        border-radius:0.5em;
        z-index:10;
        display:block;
        touch-action:none;
    }
}

button.begin-button{

    margin: 0;
    border-top-left-radius:  0;
    border-top-right-radius: 0;
    border: 0;
    border-top: 4px solid #d8d8d8;
    font-family: $bold;
    background-color: $white;
    color:$secondary-1;
    width:90%;
    font-size: 6rem;
    height: 2em;
    font-weight:900;
    cursor: pointer;
}

.container-fluid{
    background-color: $brand-primary; 
    height: 100%;
    width: 100%;
}

div.title-container{
        margin-bottom:8em;
        padding-top:5em;
    }

h1.app-title {
    font-family: $bold;
    color: $white;
    text-align:center;
    font-size:5em;
    font-weight:bold;
}

.group-button{
    font-family: $bold;
    background-color: $white;
    color:$black;
    width:85%;
    font-size: 3em;
    height: 2.1em;
    margin-bottom:0.8em;
    border-radius:0.3em;
    margin-left:1.5em;
    margin-right:1.5em;
    font-weight:bold;
    cursor: pointer;
}

button.orange-button{
    font-family: $bold;
    background-color: $white;
    color:$secondary-1;
    width:80%;
    font-size: 6rem;
    padding: 2rem 0;
    margin-bottom:0.8em;
    border-radius:0.2em;
    margin-left:10%;
    margin-right:10%;
    font-weight:900;
    cursor: pointer;
}

button.big-button {
    font-size: 6rem;
    padding: 2rem 0;
}

button.accept-button{
    position: relative;
    font-family: $bold;
    background-color: $white;
    color:#00c2a2;
    font-size: 6em;
    height: 2em;
    margin-bottom:3rem;
    border: none !important;
    border-radius:0.2em;
    min-width: 55rem;
    text-align: center;
    font-weight:900;
    cursor: pointer;
    img {
        position:absolute;
        left:2rem;
        height:80%;
        padding-bottom:1.2rem;
    }

    span {
        padding-left:3rem;
    }
}

button.decline-button{
    position: relative;
    font-family: $bold;
    background-color: $white;
    color:#dc6972;
    font-size: 6em;
    height: 2em;
    margin-bottom:0.8em;
    border: none !important;
    border-radius:0.2em;
    font-weight:900;
    min-width: 55rem;
    text-align: center;
    cursor: pointer;
    img {
        position:absolute;
        left:2rem;
        height:80%;
        padding-bottom:1.2rem;
    }
    
    span {
        padding-left:3rem;
    }
}

button.orange-button-screen{
    font-family: $bold;
    background-color: $white;
    color:$secondary-1;
    width:60%;
    font-size: 10rem;
    height: 2em;
    margin-bottom:0.8em;
    border-radius:0.2em;
    margin-left:20%;
    margin-right:20%;
    font-weight:900;
    cursor: pointer;
}


span.back-button{
    width: 8em;
    height: 8em;
    position: absolute;
    top: 3em;
    left: 3em;
    z-index:50;
    cursor: pointer;
}

span.info-button{
    font-family: $bold;
    width: 8rem;
    height: 8rem;
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    z-index:50;
    cursor: pointer;
    background: url(../static/svg/white-circle.svg);
    background-size: 100% 100%;
    color:$secondary-2;
    font-size:6.5rem;
    line-height:7.7rem;
    text-align:center;
}

span.undo-button{
    width: 5em;
    height: 5em;
    background-color: $white;
    position: absolute;
    bottom: 11em;
    left: 15%;
    z-index:50;
    cursor: pointer;
}

p.app-paragraph{
    text-align: center;
    color: $white;
    font-size:3.7em;
    font-weight:400;
    margin-bottom:0;
}

p.app-paragraph-small{
    text-align: center;
    color: $white;
    font-size:3em;
    font-weight:400;
    margin-bottom:0;
}

input.group-name {
    font-family: $bold;
    height: 2.5em;
    width: 80%;
    border: 2px solid $light-gray;
    border-radius:0.2em;
    text-align:center;
    font-size:5em;
    color:#707070;
}

input::placeholder {
    color: #bfbfbf;
}

textarea::placeholder {
    color: #bfbfbf;
}

.container-fluid {
    height: 100%;
    overflow-y: hidden;
}

input.game-select {
    font-family: $bold;
    height: auto;
    width: 45%;
    border: 2px solid $gray;
    border-radius:0.2em;
    margin-top:0.5rem;
    text-align:center;
    font-size:7em;    
    color:#707070;
}

input.game-select-screen {
    font-family: $bold;
    width: 45%;
    border: 2px solid $gray;
    border-radius:2rem;
    text-align:center;
    font-size:12rem;    
    color:#707070;
}

input.game-select:disabled {
    background-color:$white
}

input.game-select-screen:disabled {
    background-color:$white
}

.screen-container{
    background-color: $brand-primary; 
    height:100%; 
    width:100%;
    position: absolute;
}


p.screen-paragraph{
    text-align: center;
    color: $white;
    font-size:7em;
    font-weight:400;
    margin-bottom:0;
}

p.screen-paragraph-small{
    text-align: center;
    color: $white;
    font-size:4em;
    font-weight:400;
    margin-bottom:0;
}

h1.screen-title {
    font-family: $bold;
    color: $white;
    text-align:center;
    font-size:20rem;
    font-weight:bold;
}

.accordion-container {
    border: 2px solid $gray;
    border-radius:0.8em;
    background-color:$white;
    width:100%;
    min-width: 260px;
    margin-bottom:2rem;
}

.accordion-title-container {
    width:100%;
    padding-left:5%;
}

.accordion-title-container:focus {
    outline: none;
}

.accordion-item {
    height:100%;
}

.accordion-body-container {
    //background-color:$white;
    font-size:2.3rem;
    color:#808080;
    padding-left:5%;
    padding-right:5%;
    border-radius:0.8em;
}

h2.accordion-title {
    font-family: $bold;
    font-size:3em;
    color:#707070;
    height:100%;
    line-height:6rem;
    width:50%;
}

div.info-window {
    background-color:$white;
    border-radius:1rem;
    position: absolute;
    top:2rem;
    left:2rem;
    height: 82%;
    width:85%;
    z-index:55;
    padding-left:3rem;
    padding-top:3rem;
    padding-right:2rem;
}

p.app-info-paragraph {
    font-family: $bold;
    color: #808080;
    font-size:2.8rem;
    font-weight:400;
    margin-bottom:0;
}

span.rating-stars {
    img{
        height: 2rem;
        width: 2rem;
        margin-bottom:0.5rem;

    }

}

span.rewind-button {
    width: 16rem;
    height: 8rem;
    position: absolute;
    bottom: 9rem;
    left: 5rem;
    z-index:50;
    cursor: pointer;
    color:$white;
    font-size:6rem;
}

span.forward-button {
    width: 12rem;
    height: 12rem;
    position: absolute;
    bottom: 6.5rem;
    right: 5rem;
    z-index:50;
    cursor: pointer;
}

span.pause-button {
    background: url(../static/svg/white-circle.svg);
    background-size: 100% 100%;
    line-height:7.7rem;
    text-align:center;
    width: 15rem;
    height: 15rem;
    position: absolute;
    bottom: 5rem;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    z-index:50;
    cursor: pointer;
}

div.actions-row {
    position:fixed;
    bottom:0;
    width:100%;
    height:25%;
    left:0;
    z-index: 55;
    background-image: linear-gradient(to bottom, rgba(70, 177, 201, 0), $brand-primary, $brand-primary);
}

p.screen-group-small {
    text-align: center;
    color: $white;
    font-size:3rem;
    font-weight:400;
    margin-bottom:0;
    padding-right:2rem;
}



p.screen-gameid-small {
    font-family: $bold;
    position: absolute;
    top: 1rem;
    right: 2rem;
    text-align: center;
    color: $white;
    font-size:3rem;
    font-weight:400;
    margin-bottom:0;
    padding-right:3rem;
}

p.screen-description {
    font-family: $bold;
    text-align: center;
    color: $white;
    font-size:6rem;
    font-weight:400;
    margin-bottom:0;
}


p.screen-instruction {
    text-align: center;
    color: $white;
    font-size:6rem;
    font-weight:400;
    margin-bottom:0;
}

span.score {
    font-family: $bold;
    text-align: center;
    color: $secondary-1;
    font-size:4.8rem;
    font-weight:400;
    margin-bottom:0;
    margin-left:1rem;
}

span.score-add {
    position: relative;
    display:flex;
    text-align:right;
    font-family: $bold;
    color: $white;
    font-size:4.4rem;
    font-weight:400;
    padding-right: 2rem;
    float:right;
    top:-2rem;
}



div.screen-stepper {
    margin-left:auto;
    margin-right:auto;
    color:$white;
    text-align:center;
    z-index:40;
    padding-bottom: 30px;
}

span.stepper-number {
    display:inline-block;
    border: solid 4px #ffffff;
    border-radius:200px;
    height: 50px;
    width:50px;
    padding-top:8px;
    font-size:16px;
}

span.stepper-line {
    padding-left:5rem;
    border: solid 3px #ffffff;
    height:4px;
    display:inline-block;
    margin-bottom:3px;
}

button.answer-button {
    font-family: $bold;
    background-color: $white;
    color:$secondary-1;
    width:25rem;
    font-size: 6em;
    height: 24rem;
    margin-bottom:0.8em;
    border-radius:0.2em;
    margin:1.5rem;
    font-weight:900;
    cursor: pointer;
}

h2.screen-title-small {
    font-family: $bold;
    color: $white;
    text-align:center;
    font-size:8rem;
    font-weight:bold;
}

img.hint-icon {
    height: 15rem;
    width: 15rem;
}

p.hint-text {
    text-align: center;
    color: $white;
    font-size:4.5rem;
    margin-bottom:0;    
}

div.app-option-container {
    font-family: $bold;
    height: 22rem;
    width: 90%;
    border: 2px solid $light-gray;
    border-radius:0.2em;
    text-align:center;
    font-size:3rem;
    color:#808080;
    background-color:$white;
    padding-left:2rem;
    padding-right:2rem;
    cursor: pointer;
    &:active {
        border: 2px solid #38a3bc;
    }
    p.app-option-index {
        font-size:4rem;
    }
}


div.screen-option-container {
    height: auto;
    width: auto;
    border: 4px solid #86d1e2;
    border-radius:0.2em;
    text-align:center;
    font-size:4rem;
    color:$white;
    padding-left:3rem;
    padding-right:3rem;

    p.screen-option-index {
        font-family: $bold;
        font-size:5rem;
        border-radius: 2px 2px 0 0;
        color: #86d1e2;
        background-color: #FFFFFF;
        margin-left: -3rem;
        margin-right: -3rem;
    }
    .screen-option-text {
        line-height: 5rem;
    }
}

div.fade-element {
    position: absolute;
    width:100%;
    bottom:0;
    left:0;
    height: 10%;
    z-index: 55;
    background-image: linear-gradient(to bottom, rgba(70, 177, 201, 0), #46b1c9, #46b1c9);
}

p.answer-text {
    color:#707070;
    font-size:3rem;
}

.carousel-container {
    width: 450px;
    height: 140px;
    position: relative;
    perspective: 1500px;
  }
  
  #carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 7s;
  }
  
  #carousel figure {
    margin: 0;
    display: block;
    position: absolute;
    width: 450px;
    height: 30rem;
    left: 10px;
    top: 10px;
    transition: transform 7s;
    transform-style: preserve-3d;
  }

  #carousel div {
    transform-style: preserve-3d;
    
  }
  

  #carousel figure:nth-child(1) { transform: rotateY( 0deg ) translateZ( 500px ) rotateY(-0deg); }
  #carousel figure:nth-child(4) { transform: rotateY(  60deg ) translateZ( 500px ) rotateY(-60deg); }
  #carousel figure:nth-child(2) { transform: rotateY(  120deg ) translateZ( 500px ) rotateY(-120deg); }
  #carousel figure:nth-child(5) { transform: rotateY( 180deg ) translateZ( 500px ) rotateY(-180deg); }
  #carousel figure:nth-child(3) { transform: rotateY( 240deg ) translateZ( 500px ) rotateY(-240deg); }
  #carousel figure:nth-child(6) { transform: rotateY( 300deg ) translateZ( 500px ) rotateY(-300deg); }
  
.figure-container {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: transform 7s;
    //background-color:$brand-primary;
}

div.carousel-background {
    margin: 0;
    display: block;
    position: absolute;
    width: 450px;
    height: 250px;
    left: 10px;
    top: 10px;
    transition: transform 7s;
    transform-style: preserve-3d;
}

div.background-element {
    display: block;
    position: absolute;
    height: 100%;
    width: 80vw;
    right: -20vw;
    transition: transform 7s;
    background-color:$brand-primary;
    transform-style: preserve-3d;

}

div.screen-big-team {

    margin-top:20rem;
    position:relative;
    width:100%;
    height:100%;
    vertical-align:text-bottom;

    p.screen-group-big {
        font-family: $bold;
        text-align: center;
        color: $white;
        font-size:9rem;
        font-weight:400;
        margin-bottom:0;

    }

    p.screen-group-med {
        width:100%;
        font-family: $bold;
        text-align: center;
        color: $white;
        font-size:6rem;
        font-weight:400;
        margin-bottom:0;

    }

    img {
        height: 10rem;
        width: 10rem;
    }

    p.score-big {
        display: inline-block;
        font-family: $bold;
        text-align: center;
        color: $secondary-1;
        font-size:10rem;
        font-weight:400;
        margin-bottom:0;
        margin-left:1rem;
    }
    
    p.score-add-big {
        display: inline-block;
        position: absolute;
        font-family: $bold;
        color: $white;
        font-size:6rem;
        font-weight:400;
        padding-left: 2rem;
        padding-top:3rem;
    }

}

div.screen-med-team {

    position:relative;
    width:100%;
    height:100%;
    vertical-align:text-bottom;

    p.screen-group-big {
        font-family: $bold;
        text-align: center;
        color: $white;
        font-size:6rem;
        font-weight:400;
        margin-bottom:0;

    }

    p.screen-group-med {
        width:100%;
        font-family: $bold;
        text-align: center;
        color: $white;
        font-size:5rem;
        font-weight:400;
        margin-bottom:0;
        padding-top:2rem;

    }

    img {
        height: 7rem;
        width: 7rem;
    }

    p.score-big {
        display: inline-block;
        font-family: $bold;
        text-align: center;
        color: $secondary-1;
        font-size:7rem;
        font-weight:400;
        margin-bottom:0;
        margin-left:1rem;
    }
    
    p.score-add-big {
        display: inline-block;
        position: absolute;
        font-family: $bold;
        color: $white;
        font-size:5rem;
        font-weight:400;
        padding-left: 2rem;
        padding-top:1.6rem;
    }

    div.screen-group-answer {
        color:$white;
    
        p.screen-option-index {
            font-family: $bold;
            font-size:4rem;
            text-align:center;
        }
    
        p.screen-option-text {
            opacity:0.8;
            font-size:3rem;
            text-align:center;
            padding-left:10%;
            padding-right:10%;
        }
    }
}


div.screen-group-answer {
    color:$white;

    p.screen-option-index {
        font-family: $bold;
        font-size:5rem;
        text-align:center;
    }

    p.screen-option-text {
        opacity:0.8;
        font-size:4rem;
        text-align:center;
        padding-left:10%;
        padding-right:10%;
    }
}

textarea.app-open-answer {
    font-family: $bold;
    height: 40rem;
    width: 80%;
    padding-left:3rem;
    padding-right:3rem;
    padding-top:3rem;
    border: 2px solid $light-gray;
    border-radius:0.2em;
    font-size:4.5rem;
    color:#707070;
}

img.big-star {
    padding: 1rem;
    height: 12rem;
    width: 12rem;
}

div.screen-team-placement {
    position:relative;
    width:100%;
    height:100%;
    vertical-align:text-bottom;

    p.placement-group {
        font-family: $bold;
        text-align: center;
        color: $white;
        font-size:9rem;
        font-weight:400;
        margin-bottom:0;

    }

    div.placement-scores {
        height:14rem;
        text-align:center;
    }

    div.avatars {
        padding-left:5rem;
        max-height:80%;
        width:35rem;
        height:20rem;
        margin:0 auto;
    }

    img {
        height: 10rem;
        width: 10rem;
    }

    p.placement-score {
        display: inline-block;
        font-family: $bold;
        text-align: center;
        color: $secondary-1;
        font-size:10rem;
        font-weight:400;
        margin-bottom:0;
        margin-left:1rem;
    }

}

h1.placement-number {
    font-family:$bold;
    color:$white;
    font-size:40rem;
    text-align:center;
    opacity:0.4;
}

span.exit-button {
    width: 8rem;
    height: 8rem;
    position: absolute;
    top: 6rem;
    right: 3rem;
    z-index:1;
    cursor: pointer;
}

div.picture-screen-title {
    margin-top:10rem;
    z-index:50;
    background-color:$brand-primary;
    padding-top:5rem;
    padding-bottom:5rem;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
}

.team-wrapper .row:last-child {
    padding-bottom: 150px;
}

.scrollable-container {
    overflow-y: auto;
}

.facilitator-container {
    position: relative;
}

.player-container {
    position: relative;
}

.forward-button img:active {
    border: 2px solid #38a3bc;
    border-radius: 4px;
    outline: none;
}
button {
    outline: none !important;
}
button:active {
    border: 2px solid $brand-primary;
    outline: none;
}

.disable-selection {
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer */
   -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}


/* Click and hold button styles */

@-webkit-keyframes fill-button {
  100% {
      background-size: 101% 100%;
  }
}

@keyframes fill-button {

  100% {
   background-size: 101% 100% ;
  }
}

.cnh-container {
    max-width: 80rem;
    position:absolute;
    left:0;
    right:0;
    margin: 0 auto;
    bottom: 1rem;

    button {
        user-select:none;
        border:none
    }
}

#ended-not-enough {
    font-size: 4rem;
    margin: 0 auto;
    text-align: center;
    max-width: 50rem;
}



.cnh_holding {
    button {
        background: linear-gradient( $secondary-1, $secondary-1) white no-repeat 0 0;
        background-size: 0% 100%;
        animation: fill-button 0.5s forwards;
        color: black;
    }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}


/* Fade out*/

@-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

.fade-out {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 2s;animation-duration: 2s;
    -webkit-animation-fill-mode: both;animation-fill-mode: both;
}